import * as actionTypes from './actionTypes';

export const getLoggedInRequest = () => ({
    type: actionTypes.GET_LOGGED_IN_REQUEST 
});

export const setLoggedIn = isLogin => ({
    type: actionTypes.GET_LOGGED_IN,
    payload: isLogin
});

export const setUserInfo = userInfo => ({
    type: actionTypes.SET_USER_INFO,
    payload: userInfo
});

export const setUserInfos = userInfo => ({
    type: actionTypes.SET_USER_INFOS,
    payload: userInfo
});

export const addDangerList = danger => ({
    type: actionTypes.ADD_DANGER,
    payload: danger
});

export const setWagonList = wagonList => ({
    type: actionTypes.SET_WAGON_LIST,
    payload: wagonList
});

export const setAllWagonList = wagonList => ({
    type: actionTypes.SET_ALL_WAGON_LIST,
    payload: wagonList
});

export const setTrainList = trainList => ({
    type: actionTypes.SET_TRAIN_LIST,
    payload: trainList
});

export const setServiceList = serviceList => ({
    type: actionTypes.SET_SERVICE_LIST,
    payload: serviceList
});

export const setSidebarStatus = statu => ({
    type: actionTypes.SET_SIDEBAR_STATUS,
    payload: statu
});

export const setPassDataDateList = dateList => ({
    type: actionTypes.PASS_DATA_DATE_LIST,
    payload: dateList
})