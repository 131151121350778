import { createSelector } from "reselect";

const domain = state => state;

export const selectIsLogin = () => createSelector(
    domain,
    substate => substate.isLogin,
);

export const selectUserInfo = () => createSelector(
    domain,
    substate => substate.userInfo,
);

export const selectUserInfos = () => createSelector(
    domain,
    substate => substate.userInfos,
);

export const selectDangerList = () => createSelector(
    domain,
    substate => substate.dangerList
);

export const selectWagonList = () => createSelector(
    domain,
    substate => substate.wagonList
);

export const selectAllWagonList = () => createSelector(
    domain,
    substate => substate.allWagonList
);

export const selectTrainList = () => createSelector(
    domain,
    substate => substate.trainList
);

export const selectServiceList = () => createSelector(
    domain,
    substate => substate.serviceList
);

export const selectSidebarStatus = () => createSelector(
    domain,
    substate => substate.status
);

export const selectPassDataDateList = () => createSelector(
    domain,
    substate => substate.passDataDateList
);