export const GET_LOGGED_IN_REQUEST = "GET_LOGGED_IN_REQUEST";
export const GET_LOGGED_IN = "GET_LOGGED_IN";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_INFOS = "SET_USER_INFOS";
export const ADD_DANGER = "ADD_DANGER";
export const SET_WAGON_LIST = "SET_WAGON_LIST";
export const SET_ALL_WAGON_LIST = "SET_ALL_WAGON_LIST";
export const SET_TRAIN_LIST = "SET_TRAIN_LIST";
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const SET_SIDEBAR_STATUS = "SET_SIDEBAR_STATUS";
export const PASS_DATA_DATE_LIST = "PASS_DATA_DATE_LIST";