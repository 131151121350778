import { Routes } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLoggedInRequest, setUserInfos } from './store/actions'
import { selectIsLogin, selectUserInfo } from "./store/selectors";
import { Route } from "react-router-dom";
import NotFound from "./components/common/NotFound";
import axios from "axios";
import Loading from "./components/common/Loading";
import * as routers from './routers';
import { useDispatch } from "react-redux";

import './config/i18next';

function App(props) {
  const [userAuthority, setUserAuthority] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoggedInRequest());
    getUserInfo();
  }, [props.isLogin]);

  const getUserInfo = async () => {
    props.userInfo !== null &&
      await axios.get(`/user/detail/${props.userInfo.id}`)
        .then(async (response) => {
          dispatch(setUserInfos(response.data))
          setUserAuthority(response.data.authority);
        })
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes >
        {
          props.isLogin ?
            <>
              <Route path='/' element={<routers.HomepageLazy />}></Route>
              <Route path='back-side' element={<routers.BackSideLazy />} >

                <Route path='my-information' element={<routers.MyInformationLazy />} />
                <Route path="change-password" element={<routers.PasswordChange />} />

                <Route path="train-services" element={<Suspense fallback={<Loading />} >
                  <routers.TrainServicesListLazy />
                </Suspense>} />

                <Route path="train-services/add" element={<Suspense fallback={<Loading />} >
                  <routers.TrainServiceAddLazy />
                </Suspense>} />

                <Route path="train-services/update/:id" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.ServiceUpdateLazy />
                  </Suspense>
                )} />

                <Route path="train-service-order" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.TrainServiceOrderListLazy />
                  </Suspense>
                )} />

                <Route path="train-service-order/add" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.TrainServiceOrderAddLazy />
                  </Suspense>
                )} />

                <Route path="train-service-order/update/:orderId" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.TrainServiceOrderUpdateLazy />
                  </Suspense>
                )} />

                <Route path="active-news" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.ActiveNewsLazy />
                  </Suspense>
                )} />

                <Route path="active-announcements" element={(
                  <Suspense fallback={<Loading />} >
                    <routers.ActiveAnnouncementsList />
                  </Suspense>
                )} />

                {
                  userAuthority === 'manager' ?
                    <>
                      <Route path="users" element={<routers.UserListLazy />} />
                      <Route path="user/add" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.AddUserLazy />
                        </Suspense>
                      )} />

                      <Route path="suggestions" element={<Suspense fallback={<Loading />} >
                        <routers.SuggestionLazy />
                      </Suspense>} />

                      <Route path="suggestion/detail/:suggestionId" element={<Suspense fallback={<Loading />} >
                        <routers.SuggestionDetailLazy />
                      </Suspense>} />

                      <Route path="user-update/:id" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.UpdateUserLazy />
                        </Suspense>
                      )} />

                      <Route path="daily-datas/caf" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.PassDataLazy />
                        </Suspense>
                      )} />

                      <Route path="daily-datas/siemens" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.PassDataSiemensLazy />
                        </Suspense>
                      )} />

                      <Route path="news" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.NewsListLazy />
                        </Suspense>
                      )} />

                      <Route path="news/add" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.AddNewsLazy />
                        </Suspense>
                      )} />

                      <Route path="news-update/:newsId" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.UpdateNewsLazy />
                        </Suspense>
                      )} />

                      <Route path="mail-config" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.MailOperationLazy />
                        </Suspense>
                      )} />

                      <Route path="announcements" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.AnnouncementList />
                        </Suspense>
                      )} />
                      <Route path="announcements/add" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.AddAnnouncementLazy />
                        </Suspense>
                      )} />

                      <Route path="announcement-update/:announcementId" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.UpdateAnnouncementLazy />
                        </Suspense>
                      )} />

                      <Route path="cerboxes" element={(
                        <Suspense fallback={<Loading />} >
                          <routers.CerboxListLazy />
                        </Suspense>
                      )} />
                    </>
                    :
                    null
                }

              </Route>

              <Route path='*' element={<NotFound></NotFound>}></Route>
            </>
            :
            <>
              <Route path='/' element={<routers.LoginLazy />} ></Route>
              <Route path='password-remember' element={<routers.PasswordRememberLazy />} ></Route>
              <Route path='*' element={<div>noo</div>}></Route>
            </>
        }
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = createStructuredSelector({
  isLogin: selectIsLogin(),
  userInfo: selectUserInfo(),
})

export default connect(mapStateToProps)(App);
