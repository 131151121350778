import { lazy } from "react";
export const LoginLazy = lazy(() => import("./components/BeforeLogin/Login"));
export const PasswordRememberLazy = lazy(() => import("./components/BeforeLogin/PasswordRemember"));
export const HomepageLazy = lazy(() => import("./components/AfterLogin/Home/HomePage"));
export const BackSideLazy = lazy(() => import("./components/AfterLogin/Backside/Backside.js"));
export const MyInformationLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/MyInformation/MyInformation"));
export const PasswordChange = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/PasswordChange/PasswordChange"));
export const UserListLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Users/UserList"));
export const AddUserLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Users/UserAdd/AddUser"));
export const UpdateUserLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Users/UserUpdate/UpdateUser"));
export const SuggestionLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/SuggestionsAndViews/SuggestionAndViews"));
export const SuggestionDetailLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/SuggestionsAndViews/SuggestionDetail"));
export const TrainServicesListLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServices/TrainServicesList"));
export const TrainServiceAddLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServices/TrainServiceAdd/TrainServiceAdd"));
export const ServiceUpdateLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServices/TrainServiceUpdate/TrainServiceUpdate"));
export const TrainServiceOrderListLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServiceOrder/TrainServiceOrderList/TrainServiceOrderList"));
export const TrainServiceOrderAddLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServiceOrder/TrainServiceOrderAdd/TrainServiceOrderAdd"));
export const TrainServiceOrderUpdateLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/TrainServices/TrainServiceOrder/TrainServiceOrderUpdate/TrainServiceOrderUpdate"));
export const PassDataLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/PassData/PassData"));
export const PassDataSiemensLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/PassData/PassDataSiemens"));
export const AddNewsLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/News/NewsOperation/AddNews/AddNews"));
export const UpdateNewsLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/News/NewsOperation/UpdateNews/UpdateNews"));
export const ActiveNewsLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/News/ActiveNews/ActiveNews"));
export const AnnouncementList = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Announcements/AnnouncementOperations/AnnouncementList/AnnouncementList"));
export const AddAnnouncementLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Announcements/AddAnnouncements/AddAnnouncement"));
export const UpdateAnnouncementLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Announcements/UpdateAnnouncement/UpdateAnnouncement"));
export const ActiveAnnouncementsList = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Announcements/ActiveAnnouncements/ActiveAnnouncements"));
export const MailOperationLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/EmailOperations/EmalInformation"));
export const NewsListLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/News/NewsOperation/NewsList/NewsList"));
export const CerboxListLazy = lazy(() => import("./components/AfterLogin/Backside/BackSideBody/Components/Cerboxes/CerboxListContainer.jsx"));