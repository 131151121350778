import React from 'react';
import ReactDOM from 'react-dom/client';
import './responsive.scss';
import './index.scss';
import './Backside.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import reducers from './store/reducers';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './store/saga/';
import 'flag-icon-css/css/flag-icons.min.css'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["status"],
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();
let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: [applyMiddleware(sagaMiddleware)]
// });

let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://localhost:5000/';
axios.defaults.baseURL = 'https://tms-portal.herokuapp.com/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
reportWebVitals();
