import React from 'react'

function NotFound() {

  return (
    <div>
      Lütfen Bekleyiniz.
      Sayfa bulunamadı veya yükleniyor.
    </div>
  )
}
export default NotFound;