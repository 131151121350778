import { call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import * as actionTypes from '../actionTypes';
import * as api from '../../api/api';

function* getLoggedIn() {
    try {
        const result = yield call(api.getLoggedIn);
        yield put(actions.setLoggedIn(true));
        yield put(actions.setUserInfo(result.data));
    }
    catch (e) {
        console.log("girdi" + e)
        yield put(actions.setLoggedIn(false));
        yield put(actions.setUserInfo(null));
    }
};

function* watchGetLoggedInRequest() {
    yield takeEvery(actionTypes.GET_LOGGED_IN_REQUEST, getLoggedIn);
};

const projectSagas = [
    fork(watchGetLoggedInRequest),
];

export default projectSagas;