import * as actionTypes from './actionTypes';

const initialState = {
    isLogin: false,
    userInfo: null,
    dangerList: [],
    wagonList: [],
    allWagonList: [],
    trainList: [],
    serviceList: [],
    userInfos: [],
    passDataDateList: [],
    status: true
}

export default function store(state = initialState, action) {

    switch (action.type) {
        case actionTypes.GET_LOGGED_IN:
            return {
                ...state,
                isLogin: action.payload
            }

        case actionTypes.SET_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }

        case actionTypes.SET_USER_INFOS:
            return {
                ...state,
                userInfos: action.payload
            }

        case actionTypes.ADD_DANGER:
            return {
                ...state,
                dangerList: [...state.dangerList, action.payload]
            }
        case actionTypes.SET_WAGON_LIST:
            return {
                ...state,
                wagonList: action.payload
            }

        case actionTypes.SET_ALL_WAGON_LIST:
            return {
                ...state,
                allWagonList: action.payload
            }

        case actionTypes.SET_TRAIN_LIST:
            return {
                ...state,
                trainList: action.payload
            }

        case actionTypes.SET_SERVICE_LIST:
            return {
                ...state,
                serviceList: action.payload
            }

        case actionTypes.SET_SIDEBAR_STATUS:
            return {
                ...state,
                status: action.payload !== undefined ? action.payload : !state.status
            }

        case actionTypes.PASS_DATA_DATE_LIST:
            return {
                ...state,
                passDataDateList: action.payload
            }

        default:
            return state;
    }
}